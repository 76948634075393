<template>
  <main style="padding-top: 100px;">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1>Stergere cont</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="margin_30_20">
      <b-row>
        <b-col offset-md="4" md="4">
          <div class="form-group">
            <label>Adresa de email</label>
            <b-input type="email" v-model="email" placeholder="Adresa de email"></b-input>
          </div>
        </b-col>
        <b-col offset-md="4" md="4">
          <b-button variant="primary" @click="eraseAccount">Sterge Contul si Datele mele</b-button>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>

export default {
  name: 'EraseAccount',
  data() {
    return {
      isBusy: false,
      email: '',
    }
  },
  methods: {
    eraseAccount() {
      this.$swal(
          'Contul tau va fi sters',
          'Aceasta operatiune va fi efectuata manual in termen de 5 zile lucratoare',
          'success'
      )
      this.$router.push({name: 'home'})
    }
  }
}
</script>

